
import {defineComponent} from "vue";
import debug from "debug";
import {v2} from "@pons/auth";

const logger = debug("app:nav");

interface Model {
  links: { title: string; link: string; active: boolean; }[];
  email: string;
}

export default defineComponent({
  name: "App",
  components: {},
  data(): Partial<Model> {
    return {
      links: [
        {title: "Meine Aboverträge", link: "/account/contracts", active: false},
        {title: "Lizenzverwaltung", link: "/account/license-manager", active: false},
        {title: "MDM App-Konfiguration", link: "/account/license-manager/token", active: false},
      ],
      email: ""
    };
  },
  computed: {
    isActive(url: string): string {
      return location.pathname === url ? "is-active" : "";
    }
  },
  async created() {
    this.recalculateActive();
    let user = await v2.user();
    this.email = user.email;
  },
  methods: {

    recalculateActive() {
      if (!this.links)
        return;

      for (let i = 0; i < this.links.length; i++) {
        logger(location.pathname === this.links[i].link);
        this.links[i].active = location.pathname === this.links[i].link;
      }
    },

    goLink(event: Event) {
      const link = event.target as HTMLAnchorElement;
      const to = link.getAttribute("href");

      if (!to)
        return;

      if (to.match(/^https?:\/\/.*$/))
        location.href = to;
      else
        history.pushState(undefined, document.title, to);

      this.recalculateActive();
    },

    async logout() {
      await v2.clear();
      history.pushState(undefined, document.title, "/shop/");
    }
  }
});
